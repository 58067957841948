<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="customerPool-container"
  >
    <div class="title-container"><div>客服交班记录</div></div>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>客服名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.adminName"
          clearable
          @clear="() => clearInput()"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>班次:</span>
        <el-select
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.frequencyId"
          clearable
          @clear="() => clearInput()"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <br />
      <div class="common-input-container">
        <common-date
          @commonGetDate="commonGetDate"
          ref="commonReset"
        ></common-date>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="() => commonFun()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <div class="gap"></div>

    <div class="common-padding">
      <el-table
      class="common-table-haveborder common-table-bgGrey th"
      :data="tableData"
      border
    >
      <el-table-column label="客服名称">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="() => getCustDeatil(scope.row)"
            >{{ scope.row.adminName || "--" }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="companyName"
        label="所属公司"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="departmentName"
        label="所属部门"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="startTime"
        label="上班接班时间"
        :formatter="tableColumn"
      >
      </el-table-column>
      <el-table-column prop="frequencyName" label="班次"> </el-table-column>
      <el-table-column
        prop="shopNum"
        label="当天服务店铺数"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="subNum"
        label="当天登录子账号数"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="endTime"
        label="下班交班时间"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="workTime"
        label="上班时长"
        width="110"
        :formatter="tableColumn"
      ></el-table-column>
      </el-table>
      <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
      </el-pagination>
    </div>

    <detail ref="details" :drawer="drawer" @handleClose="handleClose"></detail>
  </div>
</template>
<script>
import Detail from "./children/changeRecordDetail.vue";
import { recordList } from "../../service/service.js";
import { tableColumn, Config } from "../../utils/index.js";
import CommonDate from "../../components/common/commonDate.vue";
import { mapState } from "vuex";

export default {
  components: { CommonDate, Detail },
  name: "changeRecord",
  data() {
    return {
      tableColumn,
      loading: false,
      userId: this.$sto.get(Config.constants.userInfo).user_id,
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        page: 1,
        pageSize: 10
      },
      tableData: [{ name: "111111" }],
      options: [
        {
          value: "1",
          label: "早班"
        },
        {
          value: "2",
          label: "晚班"
        }
      ],
      drawer: false
    };
  },
  computed: {
    ...mapState(["getUserInfo","comId"]),
  },
  created() {
    this.getComId()
  },
  mounted() {},
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.getTableData();
      },10)
    },
    clearInput() {
      // 筛选框清空-输入框
      this.getTableData();
    },
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.getTableData();
    },
    resetBtn() {
      this.$refs.commonReset.resetFun();
      // 重置
      this.params = {
        page: 1,
        pageSize: 10,
        companyId: this.comId
      };
      this.getTableData();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.getTableData();
    },
    async getTableData() {
      // 获取交班记录列表
      this.loading = true;
      let params = { ...this.params };
      // params.adminId = this.userId;
      let resData = (await recordList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
      this.loading = false;
    },
    commonGetDate(startTime, endTime) {
      this.params.startTime = startTime;
      this.params.endTime = endTime;
      this.getTableData();
    },
    getCustDeatil(row) {
      this.$refs.details.getDetail(row.id)
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false
    }
  }
};
</script>
<style lang="less" scoped>
.customerPool-container {
  .common-screen-container {
    padding-left: 32px;
  }
  /deep/.el-button {
    white-space: pre-line;
    text-align: left;
    line-height: 24px;
  }
  text-align: left;
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
/deep/ .el-table--border::after,
.el-table--group::after {
  width: 0 !important;
}
.gap {
  height: 16px;
  background-color: #ededed;
}
.common-screen-container {
  margin-top: 24px !important;
}
.el-tabs {
  margin-top: 32px;
}
.title-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
  align-items: center;
  position: relative;
  &::after {
    content: " ";
    background: #e6e6e6;
    width: 120%;
    height: 1px;
    position: absolute;
    left: -10%;
    bottom: -1px;
    z-index: 999999;
  }
  div {
    font-size: 24px;
    color: #101010;
  }
}
</style>
